import React from "react"
import Layout from "../components/layout"
import DemoFooter from "../components/demoFooter"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"

import Ebay from "../images/ebay.png"
import Amazon from "../images/amazon.png"
import Walmart from "../images/walmart.png"
import Gears from "../images/gears-2x.png"

class Card extends React.Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    monthlyFee: PropTypes.string.isRequired,
    setupFee: PropTypes.string,
    includesPlugin: PropTypes.bool,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        groupName: PropTypes.string.isRequired,
        features: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ),
    showDemoLink: PropTypes.bool,
    subtitle: PropTypes.string,
    includesVip: PropTypes.bool,
  }

  static defaultProps = {
    setupFee: "$500",
    includesPlugin: true,
    showDemoLink: true,
    includesVip: false,
  }

  state = {
    modal: false,
  }

  toggle = () => this.setState({ modal: !this.state.modal })

  render() {
    return (
      <div className={"col-lg-4 d-flex mb-5"}>
        <div className="card p-3 w-100 shadow-sm">
          <div className={"d-flex px-5"} style={{ minHeight: "162px" }}>
            <img src={this.props.image} className="card-img-top align-self-center" alt={`${this.props.productName} Logo`}/>
          </div>
          <div className="card-body d-flex flex-column">
            <div className={"w-100 mb-auto"}>
              <h2 className="card-title">
                {this.props.productName}
              </h2>

              {this.props.includesPlugin &&
              <h6 className={"text-muted small mb-3"}>
                (Includes eCommerce Plugin)
              </h6>
              }

              {this.props.includesVip &&
              <h6 className={"text-muted small mb-3"}>
                (Includes Ebay VIP)
              </h6>
              }

              {this.props.subtitle &&
              <h6 className={"text-muted small mb-3"}>
                {this.props.subtitle}
              </h6>
              }

              <p className="card-text mb-0">
                {this.props.monthlyFee}
              </p>
              <p className="card-text font-weight-bold">
                Monthly
              </p>

              {this.props.setupFee &&
              <React.Fragment>
                <p className="card-text mb-0">
                  {this.props.setupFee}
                </p>
                <p className="card-text font-weight-bold">
                  Setup Fee
                </p>
              </React.Fragment>
              }
            </div>

            <div className={"w-100 mt-3"}>
              {this.props.features &&
              <React.Fragment>
                <Button className={"btn btn-secondary mb-3 w-100"} onClick={this.toggle}>
                  View Features
                </Button>

                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                  <ModalBody>
                    {this.props.features.map(group => {
                      return (
                        <div key={group.groupName} className={"text-center mb-5"}>
                          <h4>{group.groupName}</h4>
                          {group.features.map(feature => {
                            return (
                              <p key={feature} className={"lead"}>{feature}</p>
                            )
                          })}
                        </div>
                      )
                    })}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>Close</Button>
                  </ModalFooter>
                </Modal>
              </React.Fragment>
              }

              {this.props.showDemoLink &&
              <Link to={"/demo-request"} className="btn btn-primary d-block">
                Request Demo
              </Link>
              }

              {!this.props.showDemoLink &&
              <Link to={"/contact-us"} className="btn btn-secondary d-block">
                Contact Us
              </Link>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function() {
  return (
    <Layout>
      <SEO title={"Pricing"} description={"Using PSS is an investment that can save you serious money in payroll, wasted data entry time, and costly product errors online. Flat monthly pricing."}/>
      <div className={"row justify-content-center text-center"}>

        <Card
          image={Ebay}
          monthlyFee={"$750"}
          productName={"eBay Marketplace Integration"}
          features={[
            {
              groupName: "Ebay Listings",
              features: [
                "Free Unlimited Listings",
                "Free Anchor Store",
                "Microfiche OEM Listings",
              ],
            },
            {
              groupName: "Catalogs",
              features: [
                "Aftermarket",
                "OEM",
                "Bring Your Own Data",
              ],
            },
            {
              groupName: "Automation",
              features: [
                "Inventory Calculations",
                "Price Calculations",
                "Gallery & Swatch Image Creation",
              ],
            },
            {
              groupName: "Fulfillment",
              features: [
                "Shipworks",
                "Shipping Easy",
                "Easypost",
              ],
            },
            {
              groupName: "DMS Integrations",
              features: [
                "Lightspeed EVO",
              ],
            },
          ]}
        />

        {false &&
        <Card
          image={Ebay}
          productName={"eBay Teardown Manager (Used Parts)"}
          monthlyFee={"$250"}
          includesVip={true}
          includesPlugin={true}
        />
        }

        {false &&
        <Card
          image={Amazon}
          monthlyFee={"$500"}
          productName={"Amazon Marketplace Integration"}
          features={[
            {
              groupName: "Amazon Listings",
              features: [
                "ASIN Recommendations",
                "ASIN Assistant",
              ],
            },
            {
              groupName: "Catalogs",
              features: [
                "Aftermarket",
                "Bring Your Own Data",
              ],
            },
            {
              groupName: "Automation",
              features: [
                "Inventory Calculations",
                "Price Calculations",
                "Gallery & Swatch Image Creation",
              ],
            },
            {
              groupName: "Fulfillment",
              features: [
                "Shipworks",
                "Shipping Easy",
                "Easypost",
              ],
            },
            {
              groupName: "DMS Integrations",
              features: [
                "Lightspeed EVO",
              ],
            },
          ]}
        />
        }

        {false &&
        <Card
          image={Walmart}
          monthlyFee={"$500"}
          productName={"Walmart Marketplace Integration"}
          includesPlugin={false}
          subtitle={"No catalog data included. Call for details."}
        />
        }

        {false &&
        <Card
          features={[
            {
              groupName: "Gateway & Checkout Methods",
              features: [
                "Stripe",
                "PayPal",
                "Apply Pay",
                "Microsoft Pay",
                "Chrome Pay",
              ],
            },
            {
              groupName: "Catalogs",
              features: [
                "Aftermarket",
                "OEM",
                "Bring Your Own Data",
              ],
            },
            {
              groupName: "Automation",
              features: [
                "Inventory Calculations",
                "Price Calculations",
                "Sales Tax Calculations",
                "Shipping Rate Calculations",
              ],
            },
            {
              groupName: "Fulfillment",
              features: [
                "Shipworks",
                "Shipping Easy",
                "Easypost",
              ],
            },
            {
              groupName: "DMS Integrations",
              features: [
                "Lightspeed EVO",
              ],
            },

          ]}
          image={Gears}
          monthlyFee={"Call For Pricing"}
          setupFee={null}
          productName={"Standalone eCommerce Plugin"}
          includesPlugin={false}
          subtitle={"Works with Dealerspike & ARI"}
        />
        }

        {false &&
        <Card
          image={Gears}
          productName={"Distributor Data Access"}
          subtitle={"Product catalog data including price & inventory for Magento, Shopify, BigCommerce, Volusion, 3dCart, and more!"}
          monthlyFee={"Starting $4,000"}
          setupFee={false}
          includesPlugin={false}
          showDemoLink={false}
        />
        }

      </div>

      <DemoFooter/>
    </Layout>
  )
}
